// This is written as an extend because I had to refactor during UAT. I wanted to maintain the old style in case the client or designer flip-flopped. These should idealy be in two separate namespaces and shared styles implemented through mixins, but didn't have time. - Ben

.custom-game-card-secondary .textBlockElement{
  margin-bottom: 1px;
  .custom-game-card + & {
    margin-top: 0;
  }
  background-color: #FFF;
  .text {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-family: $fontOne;
    padding: 10px 30px;
    @media screen and (max-width: $breakpoint-sm-max){
      flex-flow: row wrap;
      justify-content: center;
    }
    > p{
      margin-bottom: 0;
    }
    p img {
      max-width: 100px !important;
      width: 100%;
    }
    p em{
      margin: 0 10px;
      font-weight: 900;
      font-size: 14px;
      color: $primary-color;
      text-transform: uppercase;
      font-style: normal;
      width: 60px;
      display: inline-block;
      text-align: center;
    }
    &:before{
      content: "";
      display: block;
      height: 100%;
      width: 2px;
      background-color: $gray-ltx;
      margin: 0 30px;
      order: 1;
      min-height: 140px;
      @media screen and (max-width: $breakpoint-sm-max){
        width: 100%;
        height: 2px;
        min-height: auto;
        background-color: transparent;
        margin: 10px 0;
      }
    }
    ul{
      list-style: none;
      order: 2;
      line-height: 1.333;
      font-size: 18px;
      color: $primary-color;
      font-weight: 900;
      @media screen and (max-width: $breakpoint-sm-max){
        width: 100%;
        text-align: center;
      }
      strong{
        color: $secondary-color;
        font-size: 14px;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 5px;
      }
      a{
        background-color: transparent;
        border-radius: 2px;
        border: 1px $primary-color solid;
        color: $primary-color;
        padding: 5px 13px;
        font-size: 11px;
        font-weight: 900;
        text-transform: uppercase;
        margin-top: 15px;
        display: inline-block;
        transition: color $transition-standard, background-color $transition-standard;
        &:hover{
          text-decoration: none;
          color: #FFF;
          background-color: $primary-color;
        }
      }
    }
  }
}


.custom-game-card{
  @extend .custom-game-card-secondary;
  .textBlockElement{
    display: flex;
    flex-flow: row;
    &:after{
      display: none;
    }
  }
  .leftTextImage{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 300px;
    margin: 0;
    float: none;
    margin: auto;
    padding: 15px;
    img{
      width: 100px;
      height: 100px;
      object-fit: contain;
      object-position: center left;
      margin: 0;
    }
    &:before{
      content: "";
      display: block;
      width: 100px;
      height: 100px;
      width: 100%;
      max-width: 100px;
      height: 100px;
      background-image: url(https://cdn1.sportngin.com/attachments/text_block/9575/9092/ntdp_logo_small_medium.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center right;
      order: -1;
    }
    &:after{
      content: "vs";
      order: -1;
      margin: 15px;
      font-family: $fontOne;
      font-weight: 900;
      font-size: 14px;
      color: #083B81;
      text-transform: uppercase;
      font-style: normal;
      text-align: center;
    }
    br,
    .cutline{
      display: none;
    }
  }
  .textBlockElement .text{
    position: relative;
    padding: 15px;
    flex: 1 1 auto;
    &:before{
      content: "";
      display: block;
      height: 100%;
      width: 2px;
      margin: 0;
      background-color: #EEE;
      order: 1;
      position: absolute;
      left: -1px;
      top: 0;
      padding: 15px 0;
      box-sizing: border-box;
      background-clip: content-box;
    }
  }
  @media screen and (min-width: $breakpoint-md-min) and (max-width: 928px){
    .col-md-8 & {
      .textBlockElement{
        flex-flow: row wrap;
      }
      .leftTextImage{
        align-items: center;
      }
      .textBlockElement .text{
        flex-flow: row wrap;
        justify-content: center;
        &:before{
          left: 0;
          top: -1px;
          height: 2px;
          width: 100%;
          padding: 0 15px;
          display: none;
        }
      }
      .textBlockElement .text ul{
        width: 100%;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 767px){
    .textBlockElement .text ul{
      text-align: left;
    }
  }
  @media screen and (max-width: 560px){
    .textBlockElement{
      flex-flow: row wrap;
    }
    .leftTextImage{
      align-items: center;
    }
    .textBlockElement .text{
      flex-flow: row wrap;
      justify-content: center;
      &:before{
        left: 0;
        top: -1px;
        height: 2px;
        width: 100%;
        padding: 0 15px;
        display: none;
      }
    }
    .textBlockElement .text ul{
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: 400px){
    .leftTextImage{
      flex-shrink: 1;
      flex-flow: column;
      img,
      &:before{
        background-position: center;
        object-position: center;
      }
    }
  }
}
