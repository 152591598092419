
// Search Styles
.has-main-nav{
  .theme-search-bar {
    .theme-search-box {
      margin-left: auto;
      display: flex;
      flex-flow: row;
      @extend %clearfix;
      ~ .theme-search-box {
        margin-left: $site-search-spacing;
      }
    }

    .theme-search-input,
    .theme-search-submit,
    .theme-search-clear {
      float: left;
      height: $site-search-height;
      line-height: $site-search-height;
      transition: background $transition-linear;
    }

    .theme-search-input,
    .theme-search-clear { font-size: $site-search-input-font-size; }

    .theme-search-input {
      font-family: $fontOne;
      font-weight: 700;
      text-transform: uppercase;
      background: $site-search-input-background;
      border: $site-search-border-width solid $site-search-input-border-color;
      border-right: none;
      padding: 0 ($site-search-height - $site-search-border-width * 2) 0 $site-search-spacing;

      &::-ms-clear { display: none; }
      &:focus {
        outline: none;
        background: $site-search-input-background-focus;
      }
    }
    .theme-search-box .theme-search-input {
      width: 0px;
      padding: 0;
      transition: padding $transition-linear, width $transition-linear;
    }
    .theme-search-box:hover .theme-search-input,
    .theme-search-box:focus .theme-search-input {
      width: 180px;
      padding: 0 24px 0 8px;
    }

    .theme-search-submit {
      cursor: pointer;
      color: $site-search-submit-color;
      font-size: $site-search-submit-font-size;
      border: $site-search-border-width solid $site-search-submit-border-color;
      padding: 0 $site-search-spacing;
      background: $site-search-submit-background;
      &:before{
        content: "\f002";
        font-family: fontawesome;
        font-size: 17px;
        // font-weight: bold;
        color: currentColor;
      }

      &:focus,
      &:hover { background: $site-search-submit-background-focus; }
    }
    .theme-search-box:hover .theme-search-submit { background: $site-search-submit-background-focus; }

    .theme-search-clear {
      float: left;
      text-decoration: none;
      text-align: center;
      margin-left: 0 - $site-search-height - $site-search-border-width;
      margin-right: $site-search-border-width;
      width: $site-search-height;

      &:before {
        content: "✕";
        color: #9ca8af;
        font-family: FontAwesome;
        font-size: $site-search-input-font-size;
      }
    }
  }
}
