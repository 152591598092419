.has-main-nav{
  .user_mode{
    .sticky-ad{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3000;
      background: #FFF;
      transform: translateY(0%);
      transition: transform .2s .5s linear; // $transition-linear;
      &.is-not-stuck{
        transform: translateY(100%);
      }
    }
  }
  &.nav-fixed .user_mode .sticky-ad { transform: translateY(100%); }
  &.page-manager-visible .user_mode .sticky-ad { left: 60px; }
}
