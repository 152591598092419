/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    .pageEl.align-center & { text-align: center; }
    .pageEl.align-right & { text-align: right; }
    a{
      // background-color: $link-color;
      // box-shadow: 0 -3px $link-color-darken-10 inset;
      font-family: $fontOne;
      font-size: 14px;
      font-weight: 900;
      display: inline-block;
      color: $primary-color;
      .pageEl.color-primary & { color: $primary-color; }
      .pageEl.color-secondary & { color: $secondary-color; }
      .pageEl.color-white & {
        color: #FFF;
        &:hover{
          color: $tertiary-color;
        }
      }
      .pageEl.color-gray & { color: $gray-dkx; }
      padding: 0;
      // margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: color $transition-linear;
      text-transform: uppercase;
      &:hover{
        color: darken($primary-color, .5);
        // background-color: $link-color-darken-10;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: currentColor;
      content: "\f138"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      // transition: color .2s;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color: $link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}


.custom-link-primary{
  .linkElement{
    h4 {
      a {
        display: block;
        color: #FFF;
        text-align: center;
        font-size: 30px;
        font-weight: 900;
        background: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        text-transform: none;
        transition: color $transition-linear;
        &:after{
          transition: color $transition-linear;
        }
        &:hover{
          color: $tertiary-color;
          &:after{
            color: $tertiary-color;
          }
        }
      }
    }
    .goToLink:after {
      display: block;
      content:  "\f138";
      color: rgba(#FFF, .25);
      margin-top: 5px;
    }
  }
  @media screen and (max-width: $breakpoint-sm-max){
    &.custom-link-primary-mobile{

      .linkElement{
        h4 a {
          color: $primary-color;
          &:after{
            display: none;
          }
        }
      }
    }
  }
}

.custom-link-row{
  margin: 0 15px;
  .linkElement h4 a {
    color: #FFF;
    &:hover{
      color: $tertiary-color;
    }
  }
}

.link-secondary{
  + .link-secondary .linkElement{
    margin-top: 0;
  }
  .linkElement h4 a{
    display: block;
    background-color: $gray-ltx;
    color: $primary-color;
    padding: 13px;
    text-align: center;
    font-size: 11px;
    text-transform: none;
    transition: color $transition-linear, background-color $transition-linear;
    &:after{ display: none; }
    &:hover{
      background-color: $primary-color;
      color: #FFF;
    }
  }
}
