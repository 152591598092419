@mixin remove-page-spacing{
  #panelTwo,
  .siteContainer{
    padding-top: 0;
    padding-bottom: 0;
  }
}
@mixin remove-page-background{
  #panelTwo:before{
    display: none;
  }
}
.home:not(.newsPage){
  @include remove-page-spacing;
  @include remove-page-background;
}

.user_mode{
  // [data-nav-level="1"] [data-title="tickets"],
  .theme-main-nav .theme-nav-item.home,
  .theme-mobile-nav .theme-nav-item.home .theme-nav-forward,
  .theme-main-nav .theme-nav-item.custom-hidden-page,
  .theme-mobile-nav .theme-nav-item.custom-hidden-page{
    display: none !important;
  }
  @if ($sub-nav-hide-on-home == true){
    &.home .theme-sub-nav{
      display: none !important;
    }
  }
}

@mixin edit_ui_element($padding: -10px, $pseudo: after, $position: 'true', $parent-trigger: '.topNav'){
  @at-root #beta-test.edit_mode &{
    @if ($position == 'true') {
      position: relative;
    }
    &:#{$pseudo}{
      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      top: $padding;
      bottom: $padding;
      left: $padding;
      right: $padding;
      border: 1px dashed white;
      pointer-events: none;
      opacity: 0;
      transition: opacity $transition-linear;
    }
  }
  @at-root #beta-test.edit_mode #{$parent-trigger}:hover &:#{$pseudo}{
    opacity: 1;
  }
}

.main-nav-link,
.main-nav-social{
  @include edit_ui_element;
}
.custom-network-menu-toggle{
  @include edit_ui_element($pseudo: before);
}

.custom-site-footer{
  @include edit_ui_element($padding: 0, $pseudo: before, $parent-trigger: '.snFooterContainer');
}
