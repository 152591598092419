#PreviewStatsDiv .next_game {
  background-color:#333;
  color:#FFF;
  overflow:hidden;
  padding:10px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2) 100%);
  border-radius:10px 10px 0 0 / 100% 100% 0 0;
  border-bottom:1px solid #444;
}
#RosterPlayerStats {
  position: relative;

  .next_game_splits {
    border-bottom:10px solid #333;
    margin-top:0;
    padding:0;
    .NginTableControls {
      background-color:#333;
      color:#FFF;
    }
  }
  .next_game{
    .info {
      padding-top:6px;
      text-align:center;
      line-height:16px;
      width:20%;
      float:left;
    }
    .header {
      display:block;
      font-size:18px;
      margin-bottom:2px;
    }
    .vs_or_at {
      font-size:30px;
      display:block;
      margin-bottom:20px;
    }
    .time {
      display:block;
      font-size:10px;
      color:#999;
    }
    .team {
      float:left;
      width:32%;
      background:#555;
      padding:2%;
    }
    .away_team {
      padding-right:6%;
      text-align:left;
      border-radius:7px 30% 30% 0 / 100% 100% 0 0;
      box-shadow:inset -10px 2px 20px rgba(0,0,0,.4), 0 0 1px 1px rgba(255,255,255,.2);
    }
    .home_team {
      float:right;
      padding-left:6%;
      text-align:right;
      border-radius:30% 7px 0 30% / 100% 100% 0 0;
      box-shadow:inset 10px 2px 20px rgba(0,0,0,.4), 0 0 1px 1px rgba(255,255,255,.2);
    }
  }
  .team_logo {
    display:inline-block;
  }
  .team_name {
    font-size:18px;
    line-height:20px;
    height:20px;
    margin:4px 0 0;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  .stat-category-header {
    @include special-header;
    margin-top: 15px;
  }
  .statTable {
    @include line-pattern-border($pos: bottom);

    .last {
      td {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  .goToLink {
    @include link-with-arrow;
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 0;
    color: $link-color;

    &:before {
      display: none;
    }
  }
}
#RosterPlayerStats td.date,
#RosterPlayerStats td.name,
#RosterPlayerStats td.score,
#RosterPlayerStats td.split {
  white-space:nowrap;
}
#RosterPlayerStats td:first-child,
#RosterPlayerStats th:first-child { text-align:left; padding-left:10px; }
#RosterPlayerStats tr[data-toggles] td:first-child { padding-left:1.8em; }
#RosterPlayerStats tr.totals td { background-color:#555; color:#FFF; }
#RosterPlayerStats tr.season td { background-color:#DDD; border-top:1px solid #BBB; }
#RosterPlayerStats tr.subseason td { background-color:#F7F7F7; }
#RosterPlayerStats tr.game_log + tr.subseason td { border-top:1px solid #CCC; }
#RosterPlayerStats tr.subseason_totals { background: #C1C1C1; }

#RosterPlayerStats tr.totals td,
#RosterPlayerStats tr.season td,
#RosterPlayerStats tr.subseason td {

}
#RosterPlayerStats tr.subseason td {
  color: #676767;
  font-weight: bold;
}
#GameLogDiv{
  .result {
    width: 7%;
    min-width: 50px;
  }
  .opponent {
    width: 20%;
  }
}

#CareerStatsDiv .statTable{
  td.description {
    text-align: left;
  }
  tr.season td.date {
    padding-left: 20px;
  }
}
#RosterPlayerStats .inactive_notice {
  color: white;
  background-color: #9f171d;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  width: 99%;
  strong{
    text-transform: uppercase;
  }
}
i.designation {
  background-image: url(/app_images/roster_player_designations.gif);
  display: inline-block;
  &.uncommitted { background-position: 0px 0px; width: 10px; height: 10px; }
  &.suspended { background-position: -15px 0px; width: 10px; height: 10px; }
  &.rookie { background-position: -30px 0px; width: 10px; height: 10px; }
  &.pmj { background-position: -45px 0px; width: 26px; height: 10px; }
  &.injured { background-position: -76px 0px; width: 10px; height: 10px; }
  &.import { background-position: -91px 0px; width: 18px; height: 10px; }
  &.committed { background-position: -114px 0px; width: 10px; height: 10px; }
  &.affiliate { background-position: -129px 0px; width: 10px; height: 10px; }
}
.rosterTableOverflow{
  overflow-x: auto;
  overflow-y:visible;
}

.rosterListingTable{
  .photo{
    width: 3%;
    img{
      // border-radius: 100%;
      // border: 3px solid #ddd;
      display: block;
      height: 60px;
      width: 60px;
      margin: 0 auto;
    }
  }
  .number {
    width: 3%
  }
  td.photo{
    min-width: 60px;
    padding: 0;
  }
  td.number{
    font-family: $fontOne;
    font-size: 24px;
    font-weight: 700;
    color: $gray-md;
  }
  tbody td.name {
    text-align: center;
    width: 20%;
    font-weight: 700;
    a{
      color: $gray-dkx;
      &:hover{
        color: $primary-color;
      }
    }
  }
  tbody td {
    width: 10%
  }
}
