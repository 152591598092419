$tabs-default-container-baseline-color: $secondary-color;
$tabs-item-background-color: $gray-ltx;
$tabs-item-background-color-hover: $secondary-color;
$tabs-item-padding: 13px 30px;
$tabs-item-spacing: 3px;
$tabs-text-color: $gray-dkx;
$tabs-text-color-hover: #fff;
$tabs-font-family: $fontOne;
$tabs-font-size: 14px;
$tabs-line-height: 1;
$tabs-font-weight: 700;
$tabs-font-style: null;
$tabs-text-case: none;
$tabs-offset: 0;
$tab-border-base-size: 4px;
$tab-border-base-color: $secondary-color;
$tabs-container-offset: $tabs-item-spacing/2;

@mixin tab-container-styles(){
  border-bottom: $tab-border-base-size solid $tab-border-base-color;
}


// 1. From Theme
// 2. From Breakpoints
// 3. Default Styles

// 1. From Theme
.pageEl {
  .tabbedElement {
    .tabContainer {
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;
      }
    }
  }
}

// Edit Mode Fixes
.edit_mode {
  .contentTabs.rapidTabsConstruction {
    li span a {
      color: #666;
      background: transparent;
      border-right: none;
    }

    .selected span a {
      background: #fccd22;
      color: #333;
    }
  }
}

// 2. From Breakpoin
// 3. Default Styles
.contentTabs {
  // display: block;
  // list-style-type: none;
  // text-align: left;
  // width: 100%;
  // margin: 10px 0;
  // border-bottom: 3px solid #ececec;

  &:after {
    clear: both;
  }

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  li {
    // @include content-tab-item;

    span {
      // background-repeat: no-repeat;
      // background-position: bottom left;
      // text-decoration: none;
      // display: block;

      a {
        // @include content-tab;

        span {
          // display: inline;
        }
      }
    }

    &:hover,
    &.selected {
      // border-bottom: 5px solid $link-color;
    }
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs {
  margin: 0;
}

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}

/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  background: #fddd40;
  margin-bottom: 0;
  border-top: solid 1px #dda301;
  padding: 0 0 1px;

  li {
    border-right: solid 1px #dda301;
    padding-right: 0;

    a {
      font-size: 14px;
    }

    span {
      background: none;
      padding-left: 0;

      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .selected {
    background: #fde872;
  }
}




// ——————————

@mixin tab-container-style{
  // position: relative;
  // z-index: 1;
  // padding: 0 $tabs-offset;
  // &:before{
  //   content: "";
  //   width: 100%;
  //   position: absolute;
  //   bottom: $tab-border-base-size; //2px;
  //   height: $tab-border-base-size; //2px;
  //   background-color: rgba(0,0,0,.2); //$gray-lt;
  //   left: 0;
  //   z-index: -1;
  // }
}

.pageEl,
#RosterPlayerStats,
.calendarContainer{
  .contentTabs{
    @include tab-container-style;
  }
}
#rapidTabs:not(.rapidTabsConstruction){
  @include tab-container-style;
}

// 3. Default Styles
.contentTabs {
  display: flex;
  flex-flow: row wrap;
  .tab-center & {
    justify-content: center;
    text-align: center;
  }
  list-style-type: none;
  text-align: left;
  width: auto;
  // margin: 10px 0;
  + .tabContainer {
    margin-top: $page-element-margin;
    .tab-inset-content & {
      margin-top: 0;
    }
  }
  // $tabs-default-container-baseline-color: $secondary-color-lt;
  margin: 0 -$tabs-container-offset;
  &:after{
    content: "";
    display: block;
    width: 100%;
    margin: 0 $tabs-container-offset;
    @include tab-container-styles;
  }
  li{
    margin-top: $tabs-item-spacing;
    margin-left: $tabs-item-spacing/2;
    margin-right: $tabs-item-spacing/2;
    text-align: center;
    @media screen and (max-width: $breakpoint-sm-max){ flex: 1 1 30%; }
    @media screen and (max-width: 530px){ flex: 1 1 48%; }
    @media screen and (max-width: 360px){ flex: 1 1 100%; }
  }
  a{
    display: block;
    padding: $tabs-item-padding;
    color: $tabs-text-color;
    background-color: $tabs-item-background-color;
    .tab-gray & { background-color: #EEE; }
    .tab-white & { background-color: #FFF; }
    font-family: $tabs-font-family;
    font-size: $tabs-font-size;
    line-height: $tabs-line-height;
    font-weight: $tabs-font-weight;
    font-style: $tabs-font-style;
    text-transform: $tabs-text-case;
    transition: color $transition-linear, background-color $transition-linear;
    &:hover{
      color: $tabs-text-color-hover;
      background-color: $tabs-item-background-color-hover;
    }
  }
  .selected a{
    color: $tabs-text-color-hover;
    background-color: $tabs-item-background-color-hover;
  }
}

[id="RosterPlayerStats"] .contentTabs {
  margin: 0 0 0 -2px;
}
