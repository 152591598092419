// Mobile Navigation

$mobile-nav-background-color: #FFF !default;
$mobile-nav-toggle-right: true !default;
$mobile-nav-toggle-width: 44px !default;
$mobile-nav-toggle-height: 44px !default;
$mobile-nav-toggle-position: center !default;
$mobile-nav-open-toggle-position-default: true !default;
$mobile-nav-height: 90px !default;
$mobile-nav-align: center !default;
$mobile-nav-justification: center !default;

// General Layout
.has-mobile-nav{
  .topNav{
    display: flex;
    flex-flow: column;
    align-items: $mobile-nav-align;
    justify-content: $mobile-nav-justification;
    // height: $mobile-nav-height;
    position: relative;
    z-index: 200;
    background-color: $mobile-nav-background-color;
    @if $mobile-nav-toggle-right == true {
      padding-right: $mobile-nav-toggle-width;
    } @else {
      // padding-left: $mobile-nav-toggle-width;
    }
  }
  .theme-search-bar{
    .theme-search-box{
      display: none;
    }
  }

  .theme-slide-nav-toggle{
    @if $mobile-nav-toggle-position == 'top' { top: 0; }
    @if $mobile-nav-toggle-position == 'center' { top: ($mobile-nav-height/2); transform: translateY(-50%); }
    @if $mobile-nav-toggle-position == 'bottom' { top: $mobile-nav-height - ($mobile-nav-toggle-height/2); transform: translateY(-50%); }
  }
  @if $mobile-nav-open-toggle-position-default == true {
    &.slide-nav-open .theme-slide-nav-toggle{
      top: 0;
      transform: none;
    }
  }
  @if $mobile-nav-toggle-right {
    &.page-manager-visible:not(.slide-nav-open) .theme-slide-nav-toggle{
      right: calc(-100vw + 60px);
    }
    &:not(.slide-nav-open) .theme-slide-nav-toggle {
      right: -100vw;
    }
  } @else {
    &:not(.slide-nav-open) .theme-slide-nav-toggle{
      right: -$mobile-nav-toggle-width;
    }
  }

}


.has-mobile-nav{
  #sn-site-logo{
    height: $mobile-nav-height;
  }
  .theme-nav-style-slide{
    top: 50px;
  }
  .mobile-nav-social{
    font-size: 18px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: auto;
    padding: 11px 0px;
    background-color: $gray-ltx;
    &:hover{
      .sn-social-media-icon{
        opacity: .4;
      }
    }
    .sn-social-media-icon{
      opacity: 1;
      font-size: inherit;
      height: auto;
      width: 27px;
      line-height: 27px;
      margin: 4px;
      padding: 0;
      color: #FFF;
      background-color: $primary-color;
      transition: opacity $transition-linear, background-color $transition-linear;
      &:hover{
        opacity: 1;
        color: #FFF;
        background-color: $primary-color !important;
      }
      &:before{
        font-size: inherit;
        line-height: inherit;
        width: auto;
        left: auto;
      }
    }
  }

  .theme-search-submit{
    cursor: pointer;
    color: $site-search-submit-color;
    font-size: $site-search-submit-font-size;
    // border: $site-search-border-width solid $site-search-submit-border-color;
    padding: 0 $site-search-spacing;
    // background: $site-search-submit-background;
    width: auto;
    &:before{
      content: "\f002";
      font-family: fontawesome;
      font-size: 17px;
      // font-weight: bold;
      color: currentColor;
    }
  }
}
