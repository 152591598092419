#splash.user_mode{
  overflow: hidden;
  #panelTwo{
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999999;
    background: #FFF;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .page-manager-visible &{
      left: 60px;
    }
    .yieldPageContent{
      overflow-y: scroll;
    }
  }
}
.splash-logo{
  max-width: 130px;
  img{
    // max-width: 300px;
    // width: 130px;
    height: 170px;
    // margin: 0;
  }
  .user_mode & {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    margin: auto;
    transform: translate(-50%, 50%);
  }
}


.image-max-110{
  margin-top: 20px;
    img{
      max-width: 110px;
      margin-left: auto;
      margin-right: auto;
    }
}

.splash-links{
  .text a{
    font-family: $fontOne;
    font-weight: 900;
    font-size: 22px;
    color: #FFF;
    &:hover{
      color: $tertiary-color;
    }
  }
}
.splash-heading{
  .textBlockElement > h3,
  .textBlockElement .text{
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
  .textBlockElement .text{
    color: #FFF;
    font-family: $fontOne;
    font-weight: 900;
  }
}
