// Prerequisites
.has-main-nav .theme-search-wrapper .theme-search-bar{
  position: relative;
}

.custom-network-menu{
  display: none;
  .has-main-nav &.menu-is-active {
    display: flex;
    flex-flow: row;
    position: absolute;
    top: 100%;
    right: 0;
    background: #FFF;
    padding: 10px;
    z-index: 10;
    max-width: 550px;
    overflow-y: auto;
    max-height: 80vh;
    min-height: 100%;
    .network-column{
      width: 50%;
      padding: 10px 0;
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background-color: $gray-ltx;
    }

  }
}


// Toggle
.custom-network-menu-toggle{
  border: 1px solid #FFF;
  padding: 7px 10px;
  margin-left: 15px;
  text-transform: uppercase;
  font-family: $fontOne;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  color: #FFF;
  cursor: pointer;
  white-space: nowrap;
  &:after{
    content: "\f078";
    display: inline-block;
    font-family: fontawesome;
    margin-left: 5px;
  }
  &.menu-is-active:after{
    content: "\f077";
  }
  .has-mobile-nav &{
    display: none !important;
  }
}


// Item Styles
.custom-network-menu,
.custom-network-menu-mobile{
  .heroPhotoElement{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: background-color $transition-linear;
      z-index: -1;
    }
    &:hover{
      &:after{
        background-color: #EEE;
      }
    }
    a{
      max-height: 34px;
      flex-shrink: 0;
    }
    .network-team{
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      max-height: unset;
      flex-shrink: initial;
      min-height: 36px;
    }
    .network-image{
      max-height: 34px;
      flex-shrink: 0;
    }
    img{
      max-height: 34px;
      width: 100%;
      max-width: 34px;
    }
    .cutline{
      background-color: transparent;
      font-style: normal;
      font-family: $fontOne;
      color: $gray-dkx;
      padding: 0px 10px;
    }
  }
  .network-column:first-child .heroPhotoElement:after{
    left: -10px;
  }
  .network-column:last-child .heroPhotoElement:after{
    right: -10px;
  }



  .textBlockElement{
    h3{
      font-size: 16px;
      font-weight: 900;
      font-family: $fontOne;
      text-transform: uppercase;
      // border-left: 3px solid $secondary-color;
      padding: 0 10px;
      margin-bottom: 1em;
    }
  }

}



.networkBar{
  .heroPhotoElement{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    img{
      max-height: 34px;
      width: 100%;
      max-width: 34px;
    }
    .cutline{
      background-color: transparent;
      font-style: normal;
      font-family: $fontOne;
      color: $gray-dkx;
      padding: 0px 10px;
    }
  }
}




// MOBILE
.theme-mobile-nav{
  .custom-network-menu-toggle-mobile,
  .custom-network-menu-mobile{
    display: none;
  }
  .top-level-nav .custom-network-menu-toggle-mobile{
    display: table;
  }
  .top-level-nav .custom-network-menu-mobile.menu-is-active{
    display: block;
  }

  .custom-network-menu-toggle-mobile{
    width: 100%;
  }
  .custom-network-menu-mobile{
    overflow-y: hidden;
    position: absolute;
    top: 58px;
    bottom: 50px;
    right: 100%;
    transition: right .2s ease;
    background: #fff;
    display: flex;
    flex-flow: column;
    &.menu-is-active{
      // display: block;
      right: 0;
    }
    .custom-network-menu-toggle-mobile{ display: table; }
    .network-menu-container{
      overflow-y: auto;
    }
    .heroPhotoElement{
      &:after{
        z-index: 0;
      }
      .cutline{
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
      }
      > a{
        z-index: 1;
      }
    }
    .textBlockElement{
      h3{
        margin: 1.5em 0 1em 0;
      }
    }
  }
  .menu-is-active{

  }
}
