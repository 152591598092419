$yt-playlist-background: #FFF;
$yt-playlist-width: 325px;
$yt-playlist-padding-x: 10px;
$yt-playlist-padding-y: 10px;
$yt-playlist-module-height: 376px;
$yt-playlist-video-min-height: 85px;
$yt-playlist-preview-width: 85px;
$yt-playlist-preview-height: 64px;
$yt-playlist-preview-padding: 10px;
$yt-playlist-text-indent: $yt-playlist-preview-width + ($yt-playlist-preview-padding * 2); // 135px;
$yt-break-width: 964px;
$yt-playlist-list-height-column: 350px;
$yt-playlist-border-color: $gray-lt;

@mixin yt_ratio($ratio, $sidebarWidth, $breakWidth){
  .videoPlaylist{
    .featuredVideo{
      padding-right: $sidebarWidth;
      &:before{
        content: "";
        padding-bottom: $ratio;
        float: left;
      }
    }
    .mainVideo {
      width: 100%;
      height: 100% !important;
    }
    .loadedVideos{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: $sidebarWidth;
      box-sizing: border-box;
      height: 100%;
    }
    @media screen and (max-width: $breakWidth){
      .featuredVideo{
        padding-right: 0;
      }
      .loadedVideos{
        position: relative;
        width: 100%;
        height: $yt-playlist-list-height-column;
      }
    }
    .has-right-rail & {
      @media screen and (min-width: 1025px) and (max-width: 1260px){
        .featuredVideo{
          padding-right: 0;
        }
        .loadedVideos{
          position: relative;
          width: 100%;
          height: $yt-playlist-list-height-column;
        }
      }
    }
  }
}

.videoPlaylist{
  display: flex;
  flex-flow: row wrap;
  background-color: $yt-playlist-background;
  position: relative;
  .featuredVideo{
    flex-grow: 1;
    line-height: 0;
  }
  .loadedVideos{
    width: $yt-playlist-width;
    flex-basis: $yt-playlist-width;
    height: $yt-playlist-module-height;
    padding: $yt-playlist-padding-y $yt-playlist-padding-x;
    overflow: auto;
    box-sizing: content-box;
    background-color: $yt-playlist-background;
    border: 1px solid $yt-playlist-border-color;
    ul{
      li{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        position: relative;
        min-height: $yt-playlist-video-min-height;
        padding-left: $yt-playlist-text-indent;
        + li{
          border-top: 1px solid $gray-ltx;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          width: $yt-playlist-preview-width;
          height: $yt-playlist-preview-height;
          display: block;
          padding: $yt-playlist-preview-padding;
          box-sizing: content-box;
          img {
            width: 100%;
          }
        }
        h3{
          font-size: 14px;
          padding: $yt-playlist-preview-padding 0;
        }
        p{
          display: none;
          font-size: 12px;
          line-height: 1.4;
          margin-bottom: $yt-playlist-preview-padding;
        }

      }
    }
  }
  @media screen and (max-width: $yt-break-width){
    .loadedVideos{
      width: 100%;
      flex-basis: 100%;
      // margin: 15px;
      // padding: 0;
    }
  }
  .has-right-rail & {
    @media screen and (min-width: 1025px) and (max-width: 1260px){
      .loadedVideos{
        width: 100%;
        flex-basis: 100%;
        // margin: 15px;
        // padding: 0;
      }
    }
  }
}


.ratio-16-9,
.custom-youtube-playlist{
  @include yt_ratio(56.25%, 325px, $yt-break-width);
}
.ratio-4-3{
  @include yt_ratio(75%, 325px, $yt-break-width);
}


.custom-youtube-playlist{
  .pageElement{
    padding: 15px;
    background: #FFF;
  }
}
