body > img[src*="loggly.com"] {
  display: none;
}

.padding {
  $padding-desktop: 50px;
  $padding-mobile: 30px;
  padding-top: $padding-desktop;
  padding-bottom: $padding-desktop;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $padding-mobile;
    padding-bottom: $padding-mobile;
  }
  &-top {
    padding-top: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
    }
  }
  &-bottom {
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-bottom: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
    }
  }
  &-xxl{
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}

.layoutContainer{
  &.background{
    &-gray{ background-color: $gray-md; }
    &-gray-lt{ background-color: $gray-lt; }
    &-gray-ltx{ background-color: $gray-ltx; }
    &-primary{ background-color: $primary-color; }
    &-secondary{ background-color: $secondary-color; }
    &-tertiary{ background-color: $tertiary-color; }
    &-white{ background-color: #FFF; }
    &-primary-flag {
      background-color: $primary-color;
      background-image: url(../images/flag-red.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    &-jersey{
      background-color: $gray-ltx;
      background-image: url(../images/jersey.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    &-jersey-mobile{
      @media screen and (max-width: $breakpoint-sm-max){
        order: -1;
        background-color: $gray-ltx !important;
        background-image: url(../images/jersey.jpg) !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        .column + .column{
          &:before{
            content: "";
            display: block;
            width: 90%;
            margin: auto;
            height: 1px;
            background: $primary-color;
          }
        }
      }
    }
    &-rink{
      background-color: $primary-color;
      background-image: url(../images/rink-blue-large.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
// .pageEl{
//   &.background{
//     &-gray .pageElement{ background-color: $gray-md; }
//     &-gray-lt .pageElement{ background-color: $gray-lt; }
//   }
// }
.header-special{
  .textBlockElement > h3{ @include special-header; }
  &-white .textBlockElement > h3 { @include special-header(#FFF); }
  &-primary .textBlockElement > h3 { @include special-header($primary-color); }
  &-secondary .textBlockElement > h3 { @include special-header($secondary-color); }
  &-tertiary .textBlockElement > h3 { @include special-header($tertiary-color); }
  &-gray .textBlockElement > h3 { @include special-header($gray-md); }
}
.header-color{
  &-white .textBlockElement > h3 { color: #FFF; }
  &-primary .textBlockElement > h3 { color: $primary-color; }
  &-secondary .textBlockElement > h3 { color: $secondary-color; }
  &-tertiary .textBlockElement > h3 { color: $tertiary-color; }
  &-gray .textBlockElement > h3 { color: $gray-md; }
}
// .header-size{
//   &-xl .textBlockElement > h3{
//     font-size: 48px;
//     @media screen and (max-width: $breakpoint-sm-max){
//       font-size: 24px;
//     }
//   }
// }
.textBlockElement > h3:only-child{ margin-bottom: 0; }
.text{
  &-white .textBlockElement .text a,
  &-white .textBlockElement .text a:hover,
  &-white .textBlockElement .text{ color: #FFF; }
  &-primary .textBlockElement .text{ color: $primary-color; }
  &-secondary .textBlockElement .text{ color: $secondary-color; }
  &-tertiary .textBlockElement .text{ color: $tertiary-color; }
}
.background-image{
  &.layoutContainer{
    position: relative;
    .background-element{
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      opacity: .25;
    }
  }
  &.pageEl{
    .user_mode &{ display: none; }
    .edit_mode &{
      &:before{
        max-width: 300px;
        margin: 0 auto;
        display: block;
        padding: 1em;
        text-align: center;
        content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
      }
      img{
        max-width: 300px;
        margin: 0 auto;
        border: 2px solid #FFF;
        box-shadow: 0px 0px 15px rgba(0,0,0,.5);
      }
    }
  }
}


.connect-wrapper{
  &.background-rink {
    background-color: $primary-color;
    background-image: url(../images/rink-blue.jpg); // , url(../images/rink-blue-preview.jpg),;
    background-repeat: no-repeat; // , no-repeat;
    background-position: center center; // , center center;
    background-size: cover; // , cover;
    display: flex;
    flex-flow: column;
    .user_mode & .layoutContainer{
      background-color: transparent;
      background-image: none;
    }
  }
  .padding + &,
  .padding-bottom + &{
    @media screen and (max-width: $breakpoint-sm-max){
      margin-top: -30px; // $padding-mobile
    }
  }
}

.custom-text-one{
  .textBlockElement{
    display: flex;
    flex-flow: column;
  }
  .textBlockElement > h3{
    @include special-header(#FFF);
    font-size: 42px;
    @media screen and (max-width: $breakpoint-sm-max){
      font-size: 24px;
    }
    margin-bottom: 0;
  }
  .leftTextImage,
  .rightTextImage,
  .smallTextImage,
  .mediumTextImage,
  .largeTextImage,
  .originalTextImage{
    float: none;
    margin: auto;
    order: -1;
    width: auto !important;
    min-height: auto !important;
    padding: 30px;
    img{
      max-height: 105px;
      width: auto !important;
    }
  }

}

.hrElement{
  .color-tertiary.pageEl & {
    margin: auto;
    @include page-element-margin;
    max-width: 80px;
    width: 100%;
    background: none;
    background-color: $tertiary-color;
    height: 1px;
  }
}


.custom-text-cta{
  &.text-cta-frame .text-cta-container{
    padding: 25px;
    background-color: #FFF;
  }
  .textBlockElement{
    position: relative;
    background-color: $primary-color;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 345px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 5px;
    p{
      line-height: 1;
      margin-bottom: 0;
      + p{
        margin-top: 15px;
      }
      a{
        font-family: $fontOne;
        font-weight: 900;
        font-size: 24px;
        color: #FFF;
        display: block;
        &:hover{
          color: $tertiary-color;
          text-decoration: none;
        }
      }
    }
    .leftTextImage,
    .rightTextImage,
    .smallTextImage,
    .mediumTextImage,
    .largeTextImage
    .originalTextImage{
      float: none;
      margin: 10px auto 25px auto;
      width: 100% !important;
      max-width: 100px;
      height: 1px;
      min-height: auto !important;
      background-color: $tertiary-color;
      img{
        display: none;
      }
    }
  }
  @media screen and (max-width: $breakpoint-sm-max){
    &.text-cta-frame .text-cta-container{
      padding: 0;
    }
    .text-cta-container{
      .textBlockElement{
        width: auto;
        margin: 0 -#{$mobile-padding * 2};
      }
    }
  }
}

//
.column-1-flex{
  .column-1{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    > .pageEl:not(.custom-link-row){
      width: 100%;
    }
  }
}

.layoutContainer.custom-grid{
  display: flex;
  flex-flow: row wrap;
  + .layoutContainer.custom-grid{
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $gray-ltx;
      padding: 0 15px;
      background-clip: content-box;
      box-sizing: border-box;
    }
  }
  @media screen and (min-width: $breakpoint-md-min){
    .column{
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .column + .column{
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background-color: $gray-ltx;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 15px 0;
        background-clip: content-box;
        box-sizing: border-box;
      }
    }
  }
}
.custom-image-event{
  img{
    max-width: 135px;
  }
}
.custom-text-event{
  @media screen and (min-width: $breakpoint-md-min){
    .textBlockElement{
      padding: 0 15px;
    }
  }
  .textBlockElement > h3{
    font-family: $fontOne;
    font-weight: 700;
    font-size: 14px;
    color: $secondary-color;
  }
  .text h2{
    font-family: $fontOne;
    font-weight: 900;
    font-size: 18px;
    color: $primary-color;
  }
  .text p{
    font-family: $fontOne;
    font-size: 14px;
  }
  .text h2 + p{
    margin-top: .5em;
  }
}
.visually-hidden{
  @include visually-hidden;
}
.hide-in-mobile{
  @media screen and (max-width: $breakpoint-sm-max){
    display: none;
  }
}


.codeElement [id^="twitter-widget"]{
  width: 100% !important;
  background: #FFF;
  .timeline-Widget--edge{
    border-radius: 0;
  }
}


.layout-66-33,
.layout-33-66,
.layout-50-50{
  &.custom-columns-md-9-3{
    @media screen and (min-width: $breakpoint-md-min) {
      .column-1{ width: (9/12 * 100%); }
      .column-2{ width: (3/12 * 100%); }
      &.full-width-container{
        .column-1{ max-width: ( ($content-area-width - $content-area-padding) * (9/12) ) }
        .column-2{ max-width: ( ($content-area-width - $content-area-padding) * (3/12) ) }
      }
    }
  }

}
