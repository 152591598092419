// Sponsor Scoller __________________________________
  // Requirements
  // + CSS code Snippet (sponsorscroller)
  // + JS code Snippet (sponsorscroller)
  // + Add class "sponsorScoller" to a single column layout container
  // + Add single photo elements to container
  $sponsor-slider-nav-font-size: 24px;
  $sponsor-slider-nav-font-weight: bold;
  $sponsor-slider-nav-text-color: #CCC;
  $sponsor-slider-nav-width: 35px;
  $sponsor-slider-max-items-desktop: 4;
  $sponsor-slider-item-width-desktop: (($content-area-width - (($content-area-padding + $sponsor-slider-nav-width) * 2)) / $sponsor-slider-max-items-desktop);

.user_mode .layoutContainer.sponsorScroller {

    .inner-layout {
      position: relative;
      margin-bottom: 15px;
    }

    .column {
      // border-top: 1px solid $gray-md;
      // border-bottom: 1px solid $gray-md;
      // max-width: 970px;
      // margin: 0 15px 15px;
    }

    .pageEl {
      display: inline-block;
      width: $sponsor-slider-item-width-desktop; // Set logo module width
      text-align: center;
      padding: 0;
      vertical-align: middle;
      box-sizing: border-box;
      padding: 0;
      transition: 0.2s ease-in;
      transition: background-color $transition-standard;

      // &:hover { background-color: #eeeeee; }

        .pageElement.heroPhotoElement {
          overflow: hidden;
          margin: 0;
          display: flex;
          height: 200px; // Set logo module height
          align-items: center;
          justify-content: center;

            img {
                height: auto;
                width: 100%;
                transition: 0.2s ease-in;
                border: none;
                max-width: 150px;
            }
        }
    }
}

.edit_mode {

  .sponsorScroller {

    .pageElement.heroPhotoElement img { max-width: 200px; }

  }

}

.user_mode .sponsorScroller .column-1 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  padding: 0;

  #sponsorScrollerGroup {
    position: relative;
    left: 0;
    padding: 0;
    // margin: 0 21px;
    margin: 0 ($sponsor-slider-nav-width + $desktop-padding);
    @media screen and (max-width: $breakpoint-sm-max){
      margin:  0 ($sponsor-slider-nav-width + $mobile-padding);
    }
    width: auto;
  }

  #sponsorScrollerNext,
  #sponsorScrollerPrev {
    width: $sponsor-slider-nav-width + $desktop-padding;
    @media screen and (max-width: $breakpoint-sm-max){
      width: $sponsor-slider-nav-width + $mobile-padding;
    }
    height: 200px;
    position: absolute;
    top: 0;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.5s ease;
    // border-right: 1px solid $gray-md;
    // border-left: 1px solid $gray-md;
    display: table;

    &:after {
      content: '\f104';
      font-family: fontAwesome;
      margin: 0 auto;
      font-size: $sponsor-slider-nav-font-size;
      font-weight: $sponsor-slider-nav-font-weight;
      color: $sponsor-slider-nav-text-color;
      display: table-cell;
      vertical-align: middle;
      transition: color $transition-standard;
    }

    &:hover {
      // background-color: #eeeeee;

      &:after { color: $gray-dk; }

    }

    &.sit-left {
      left: 0;
      padding-left: $desktop-padding;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-left: $mobile-padding;
      }
    }
    &.sit-right {
      right: 0;
      padding-right: $desktop-padding;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-right: $mobile-padding;
      }

      &:after { content: '\f105'; }

    }

  }


}

@media only screen and (max-width: 1024px){

  .user_mode .layoutContainer.sponsorScroller {

    .column { margin: 0; }

    .inner-layout {
      padding: 0 15px 15px;
    }

  }
}
