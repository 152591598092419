// Config
// Global Layout
// Page Manager
// Page Title

// $primary-color: #083B81;
// $secondary-color: #BA0C2F;
// $tertiary-color: #FFD106;
// $gray-ltx: #EEE;
// $gray-lt: #CCC;
// $gray-md: #999;
// $gray-dk: #666;
// $gray-dkx: #333;


// Variables
$desktop-padding: 15px;
$mobile-padding: 10px;

$content-area-width: 1140px;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #FFF;

$content-container-margin: 15px;
$enable-full-width-layout: true;
$include-page-title-in-content-area-background: true;

$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-intermediate-min: 768px;
$breakpoint-intermediate-max: 999px;

// UI Elements
$global-ui-border-size: 1px;
$global-ui-border-color: gainsboro;

// Global Layout
@mixin adjust-margin-x($multiplier: 1, $responsive: true){
  margin-left: $desktop-padding * $multiplier;
  margin-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      margin-left: $mobile-padding * $multiplier;
      margin-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin page-element-padding(){
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin(){
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding(){
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1, $responsive: true){
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-left: $mobile-padding * $multiplier;
      padding-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-container-padding-vert($multiplier: 1, $responsive: true){
  padding-top: $desktop-padding * $multiplier;
  padding-bottom: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $mobile-padding * $multiplier;
      padding-bottom: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-area-width{
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}
@mixin content-area-container-style{
  background-color: $content-area-background;
  box-shadow: 0px 2px 9px rgba(black, .05);
}

// Page Manager
$page-manager-width: 60px;


// Page Title
$page-title-full-width: false;
$page-title-full-width-text: false;
$page-title-container-background: $content-area-background;
$page-title-text-color: #FFF;
$page-title-font-size: 30px;
$page-title-text-case: null;
$page-title-font-weight: 900;
$page-title-text-align: null;

@mixin page-title-container{
  // site page title container css here
  // display: flex;
  padding: 35px 30px 20px 30px;
  // align-items: center;
  background-image: url(../images/flag-red.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
@mixin page-title-text{
  // border-bottom: $global-ui-border-size solid $global-ui-border-color;
  // site page title text css here
  padding: 0;
}
@mixin page-title-image-container{
  // site page title w/image container css here
}

// Account Navigation Options
$account-nav-bottom: false;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #FFF;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 14px;
$account-nav-height-desktop: 40px;
$account-nav-height-mobile: 44px;
$account-nav-z-index: 399;
$account-nav-icon-width: 32px;

$site-tagline-spacing: 15px; // Need this for "_source/sass/_navigation/_network_nav_bar.scss" —— Try to phase this out

// Title Bar Options
$nav-title-bar-height: 47px;
$nav-title-bar-height-fixed: .01px;
$nav-title-bar-band-background-color: $secondary-color;
$nav-title-bar-text-color: #FFF;
$nav-title-bar-font-size: 16px;
$nav-title-bar-line-height: 1;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 900;


// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 100px;
$main-nav-height-fixed: 50px;
$main-nav-band-background-color: $primary-color;
$main-nav-flex-alignment: flex-start;
$main-nav-item-padding: 10px;

$main-nav-cascade-styles: true;
$main-nav-child-indicator-cascade-styles: false;
$main-nav-more-plus-buffer: true;
$main-nav-font-size: 14px;
$main-nav-text-case: uppercase;
$main-nav-text-align: center;
$main-nav-text-weight: 700;
$main-nav-text-color: #FFF;
$main-nav-text-color-hover: $primary-color;
$main-nav-text-color-selected: $main-nav-text-color-hover;
$main-nav-text-color-disabled: slategrey;
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: #FFF;
$main-nav-item-background-color-selected: $main-nav-item-background-color-hover;
$main-nav-item-background-color-disabled: transparent;


// Dropdown Navigation Options
$dropdown-nav-min-width: 200px;
$dropdown-nav-min-height: 35px;
$dropdown-nav-transition-distance: 20px;

$dropdown-nav-cascade-styles: true;
$dropdown-nav-container-background-color: #FFF;
$dropdown-nav-container-border-size: 0px;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid transparent;
$dropdown-nav-font-family: $fontOne;
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: none;
$dropdown-nav-text-weight: bold;
$dropdown-nav-font-size: 14px;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: $gray-dk;
$dropdown-nav-text-color-hover: $primary-color;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover;
$dropdown-nav-text-color-disabled: $main-nav-text-color-disabled;
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: $gray-ltx;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: $main-nav-item-background-color-disabled;

$dropdown-nav-2-container-background-color: $dropdown-nav-container-background-color;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;


// Sub Navigation Options
$sub-nav-hide-on-home: true;
$sub-nav-more-plus-buffer: true;
$sub-nav-font-size: 13px;
$sub-nav-text-color: #FFF;
$sub-nav-text-color-hover: #FFF;
$sub-nav-text-color-disabled: #CCC;
$sub-nav-item-background-color-disabled: transparent;
$sub-nav-band-background-color: $gray-dk;
$sub-nav-height: 50px;
$sub-nav-height-fixed: 25px;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: transparent;
$sub-nav-item-text-case: none;
$sub-nav-item-text-weight: normal;
$sub-nav-item-padding: 10px;
$sub-nav-flex-alignment: flex-start;

// Sub Navigation Title Options
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: none;
$sub-nav-title-text-weight: normal;
$sub-nav-title-background-color: transparent;
$sub-nav-title-text-color: #FFF;
$sub-nav-title-padding: 10px;
$sub-nav-title-divider-content: '\f105';
$sub-nav-title-divider-font: 'fontawesome';

// Sub Navigation Dropdown Options
$sub-nav-dropdown-font-size: 13px;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 200px;
$sub-nav-dropdown-min-height: 35px;
$sub-nav-dropdown-transition-distance: 20px;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;


// Search (Desktop) Options
$site-search-height: 30px;
$site-search-spacing: 8px;
$site-search-border-width: 0px;
$site-search-input-font-size: 12px;
$site-search-input-color: $gray-md;
$site-search-input-border-color: transparent;
$site-search-input-background: #FFF;
$site-search-input-background-focus: #FFF;
$site-search-submit-color: #fff;
$site-search-submit-border-color: transparent;
$site-search-submit-background: transparent;
$site-search-submit-background-focus: $gray-dkx;
$site-search-submit-font-size: 12px;


// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height + $main-nav-height + $sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height + $main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base


// Site Logo Options
$site-logo-size: $desktop-nav-height;  // Remove after replacing these in code base
$site-logo-size-fixed: $desktop-nav-height-fixed;  // Remove after replacing these in code base
$site-logo-width-desktop: 115px; // $site-logo-size;
$site-logo-height-desktop: $site-logo-size;
$site-logo-width-desktop-fixed: $site-logo-size-fixed;
$site-logo-height-desktop-fixed: $site-logo-size-fixed;
$site-logo-width-mobile: 56px;
$site-logo-height-mobile: 72px;
$site-logo-padding: 0px 0px 8px 0px;
$site-logo-offset: $site-logo-width-desktop + 43px;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed + 15px;


// Mobile Nav Bar
$mobile-nav-background-color: #FFF;
$mobile-nav-toggle-right: false;
$mobile-nav-toggle-width: 44px;
$mobile-nav-toggle-height: 44px;
$mobile-nav-toggle-position: center;
$mobile-nav-open-toggle-position-default: false;
$mobile-nav-height: 60px;
$mobile-nav-align: center;
$mobile-nav-justification: center;


// Footer Colophon
$footer-colophon-se-logo-black-color: false;
$footer-colophon-se-logo-white-color: true;
$footer-colophon-se-logo-white: false;
$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: $gray-dkx;
$footer-colophon-content-background-color: transparent;
$footer-colophon-item-padding: 10px;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;
$footer-colophon-font-size: 10px;
$footer-colophon-line-height: 1.75;
$footer-colophon-text-case: none;
$footer-colophon-text-color: #FFF;
$footer-colophon-link-color: #FFF;
$footer-colophon-link-color-hover: #EEE;


// Site Background
$site-background-image-full-height: false;


// Page Banner
$site-banner-full-width: true;
$site-banner-full-width-text: false;

$site-banner-padding: 15px;
$site-banner-fixed-min-height: 100px;
$site-banner-font-size: 20px;
$site-banner-font-size-min: 20px;
$site-banner-font-size-max: 72px;
$site-banner-text-color: white;
$site-banner-font-family: null;
$site-banner-line-height: 1;
$site-banner-font-weight: normal;
$site-banner-text-case: null;
$site-banner-text-align: center;

@mixin site-banner-container{
  // site banner container css here
}

$site-banner-headline-text-color: inherit;
$site-banner-headline-font-size: null;
$site-banner-headline-font-family: null;
$site-banner-headline-line-height: null;
$site-banner-headline-font-weight: null;
$site-banner-headline-text-case: null;
$site-banner-headline-text-align: null;
$site-banner-title-text-color: inherit;
$site-banner-title-font-size: inherit;
$site-banner-title-font-family: null;
$site-banner-title-line-height: inherit;
$site-banner-title-font-weight: inherit;
$site-banner-title-text-case: null;
$site-banner-title-text-align: null;
$site-banner-tagline-text-color: inherit;
$site-banner-tagline-font-size: null;
$site-banner-tagline-font-family: null;
$site-banner-tagline-line-height: null;
$site-banner-tagline-font-weight: null;
$site-banner-tagline-text-case: null;
$site-banner-tagline-text-align: null;


// Element Options
$element-options-flex-justification: flex-end;
$element-options-font-size: 12px;
$element-options-text-wieght: 700;
$element-options-text-color: $primary-color;
$element-options-text-color-hover: darken($primary-color, 5%);
$element-options-font-family: $fontOne;
$element-options-text-transform: uppercase;
$element-options-icon-margin: .7ch;
$element-options-icon-text-space: 1.5ch;


// Mixins/Functions
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
