
/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Buttons
// 5. Aspect Ratio Adjustments
// 6. Accomidations for Small Columns & Mobile Views

// Variables
$control-height-md: 60px;
$control-height-sm: 40px;
$nss-headline-font: $fontOne;
$nss-headline-font-size: 32px;
$nss-headline-font-weight: 900;
$nss-headline-text-case: none;
$nss-headline-text-color: #FFF;
$nss-headline-line-height: 1.2;
$nss-headline-background-color: transparent;
$nss-headline-text-shadow: null;

$nss-subtext-font: $fontOne;
$nss-subtext-font-size: null;
$nss-subtext-font-weight: normal;
$nss-subtext-text-case: null;
$nss-subtext-text-color: #FFF;
$nss-subtext-line-height: 1;
$nss-subtext-background-color: transparent;

$nss-nav-arrow-background-color: transparent;
$nss-nav-arrow-color: #CCC; //$secondary-color-alt;
$nss-nav-arrow-background-color-hover: $nss-nav-arrow-background-color;
$nss-nav-arrow-color-hover: #FFF;
$nss-nav-arrow-font-size: 48px;
$nss-nav-arrow-width: 30px;
$nss-nav-arrow-text-shadow: null;
$nss-pagination-color: rgba(#FFF, .4);
$nss-pagination-color-hover: #FFF;
$nss-pagination-color-current:  $nss-pagination-color-hover;
$nss-pagination-item-width: 12px;
$nss-pagination-item-height: 12px;

// $nss-article-image-max-height: 560px;

@mixin nss-cover-image{
  position: absolute;
  object-fit: cover;
  object-position: center;
  height: 100% !important;
  top: auto !important;
}

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  // max-height: $nss-article-image-max-height;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  background-color: #fff;
  &:before{
    content: "";
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    background-image: linear-gradient(to top, rgba(black, 100%) 0%, rgba(#F8F8F8, 0%) 100%);
    opacity: .7;
    mix-blend-mode: multiply;
    z-index: 1;
  }

  &.current {
    z-index: 1;
  }

  img {
    position: absolute;
    @include nss-cover-image;
  }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: $control-height-md;
  z-index: 1;
  width: 100%;
  margin: 0;

  h1 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 20px 20px 0;
    font-size: rem(18);
    text-transform: none;

    &:after {
      content: "";
      clear: both;
    }

    a {
      font-size: $nss-headline-font-size;
      font-family: $nss-headline-font;
      font-weight: $nss-headline-font-weight;
      line-height: $nss-headline-line-height;
      text-transform: $nss-headline-text-case;
      text-shadow: $nss-headline-text-shadow;
      color: $nss-headline-text-color;
      text-align: center;
      text-decoration: none;
      display: block;
      display: flex;
      justify-content: center;
      width: 100%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // -o-text-overflow: ellipsis;

      // Sadly, `line-clamp` doesn't work in non-webkit browsers, so we need
      // to restrict it with this hack. `white-space: nowrap` serves as a
      // fallback for other browsers with one line of text instead of two.
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        // display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;

        .col-md-3 & {
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  p {
    @extend %clearfix;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 0 -6px 0;
    line-height: 1.5em;
    font-size: 14px;
    font-style: italic;

    span {
      display: block;
      float: left;
      width: auto;
      max-width: calc(100% - 122px);
      height: 1.5rem;
      padding-right: 10px;
      line-height: 1.5rem;
      color: #fff;
      text-indent: 10px;
      overflow: hidden;
      @include truncate-text;

      &:empty { display:none; }
    }

    a {
      float: left;
      width: 120px;
      margin: 0;
      height: 1.5rem;
      line-height: 1.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem); // Subtract width of left/right arrows
  height: $control-height-md;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  background-color: transparent;
  cursor: default;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .col-md-3 & {
    font-size: .5em;
  }

  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: 4px;

    .col-md-3 & {
      padding: 6px;
    }

    @media only screen and (max-width: 450px) {
      padding: 6px;
    }

    &:before {
      content: '';
      display: block;
      width: $nss-pagination-item-width;
      height: $nss-pagination-item-height;
      border-radius: 100%;
      background-color: $nss-pagination-color;
      transition:
        background-color .1s ease-in-out;
    }

    &:hover:before{
      background-color: $nss-pagination-color-hover;
    }
    &.current:before {
      background-color: $nss-pagination-color-current;
    }
  }

  img {
    display: none;
    width: 100%;
  }
}

// 3. Controls
.newsSlideShow-controls {}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  background-color: $nss-nav-arrow-background-color;
  box-shadow: none;
  text-align: center;
  color: $nss-nav-arrow-color;
  border-radius: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  line-height: 1;
  width: $nss-nav-arrow-width;

  &:hover {
    color: $nss-nav-arrow-color-hover;
    background-color: $nss-nav-arrow-background-color-hover;
  }
  &:before {
    font-size: $nss-nav-arrow-font-size;
    font-weight: normal;
    line-height: 1;
    content: '\f104'; //'\f060'; // fa-arrow-right
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    transition: color $transition-linear;
    text-shadow: $nss-nav-arrow-text-shadow;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f105'; // '\f061'; // fa-arrow-left
}

.newsSlideShow-empty {
  text-align: center;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

// 4. Buttons
.newsSlideShow-more.button-small {
  font-family: $fontOne;
  font-size: 14px;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  padding: 0;
  line-height: 1.3;
  text-decoration: none;
  transition: color $transition-linear;
  text-transform: uppercase;

  background-color: transparent;
  border: 0;
  margin: 0;
  &:after{
    color: currentColor;
    content: "\f138"; // Right Arrow
    font-family: FontAwesome;
    display: inline-block;
    line-height: .8em;
    margin: 0 0 0 6px;
    padding: 0;
    position: relative;
  }
  &:hover{
    background-color: transparent;
    color: $tertiary-color;
  }
}

.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 6. Accommodations for Small Columns & Mobile Views
@media only screen and (min-width: 767px) {
  .col-md-3,
  .col-md-4 {
    .newsSlideShow-headline { bottom: $control-height-sm }
    .newsSlideShow-thumbnails { height: $control-height-sm; }
    .newsSlideShow-headline {
      h1 a {
        font-size: rem(18);
      }
      p {
        display: none;
      }
    }
  }
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    .aspect_ratio_4_1,
    .aspect_ratio_3_1,
    .aspect_ratio_2_1 {
      .newsSlideShow-headline {
        // padding-bottom: 2em;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .newsSlideShow-headline { bottom: $control-height-sm }
  .newsSlideShow-thumbnails { height: $control-height-sm; }
  .newsSlideShow-headline {
    .aspect_ratio_4_1 &,
    .aspect_ratio_3_1 &,
    .aspect_ratio_2_1 & {
        // padding-bottom: 2em;
    }
    h1 a {
      font-size: rem(18);
    }
    p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .newsSlideShow-headline {
    p {
      display: none;
    }
  }
}



// CUSTOM

.news-slide-show-custom{
  .newsSlideShow-headline{
    max-width: $content-area-width;
    left: 50%;
    transform: translateX(-50%);
  }
  .newsSlideShow-headline p{
    display: none;
  }
  .newsSlideShow-headline h1 a{
    font-size: 42px;
    @media only screen and (max-width: 450px) { font-size: rem(18); }
    @media only screen and (min-width: 767px) {
      .col-md-3 &,
      .col-md-4 & {
        font-size: rem(18);
      }
    }
  }
  // .aspect_ratio_original { @include aspect-ratio(3,2); }

}


// Options
.pageEl{
  &.max-height-400 .newsSlideShow-container{ max-height: 400px; }
  &.max-height-450 .newsSlideShow-container{ max-height: 450px; }
  &.max-height-500 .newsSlideShow-container{ max-height: 500px; }
  &.max-height-550 .newsSlideShow-container{ max-height: 550px; }
  &.max-height-600 .newsSlideShow-container{ max-height: 600px; }
  &.max-height-650 .newsSlideShow-container{ max-height: 650px; }
  &.max-height-700 .newsSlideShow-container{ max-height: 700px; }
  &.max-height-750 .newsSlideShow-container{ max-height: 750px; }
}
